import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { useSegmentTrack } from './useSegmentAnalytics'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum AnalyticsButton {
  // Navigation
  home,
  search,
  add,
  contactSupport,
  logout,
  userProfileMenu,
  dismiss,
  login,
  signup,
  continue,
  // Feed Filters
  feedFilter,
  switcherSelection,
  // Url
  urlItem,
  urlLike,
  urlRemoveLike,
  urlDislike,
  urlRemoveDislike,
  // urlInfo,
  urlSave,
  urlRemoveSaved,
  urlOpenExternal,
  urlShare,
  // urlShareExternal,
  urlUpload,
  // User
  userFollow,
  userUnfollow,
  userSettings,
  // userMoreActions,
  userItem,
  // userShareExternal,

  // Node
  nodeItem,
  nodeFollow,
  nodeUnfollow,
  // nodeShare,

  // Login
  facebookAuth,
  twitterAuth,
  appleAuth,
  googleAuth,
  emailAuth,
  termsOfServices,
  privacyPolicy,

  // Report
  report,

  // Onboarding
  profilePictureUpload,
  topicsSubmit,
  editUser,
  saveUser,
  enableNSFW,

  // Time Capsule
  openTimeCapsule,
  nextStory,
  previousStory,
  shareStory,
}

export const useTrackClick = () => {
  const track = useSegmentTrack()
  const router = useRouter()

  return useCallback(
    (button: keyof typeof AnalyticsButton, properties?: object) =>
      track('click', { ...properties, button, url: router.asPath }),
    [track, router.asPath]
  )
}
